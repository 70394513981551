<template>
  <base-main-container>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate size="68">
        AI is preparing your resource
      </v-progress-circular>
    </v-overlay>

    <template v-slot:centered>
     
        <v-card class="my-card rounded-lg" elevation="0">
          <v-card-title>Dicdionary: Any language, Any word </v-card-title>
          <v-card-text>
            <v-text-field
              v-model="topic"
              class="mt-4 ml-2"
              label="Word to be defined"
              outlined
              :rules="[rules.required]"
             @keydown.enter="sendRequest()"
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn class="mr-2" outlined link to="newresource">
              <v-icon class="mr-2"> mdi-backspace</v-icon> Cancel
            </v-btn>
 
            <v-btn :disabled="!topic" color="#212121" @click="sendRequest()"
            
            >
              <h3 v-if="!topic">Generate please</h3>
              <h3 v-else style="color: white">Generate please</h3>
            </v-btn>
          </v-card-actions>
          <v-card-text  v-if="response" class="pa-5">
            <v-divider></v-divider>
             <div 
            
             v-html="response"
             class="custom-font mt-10"
             ></div>
            
          </v-card-text>
        </v-card>
      
    </template>
    <v-snackbar v-model="showSnackbar" :timeout="6000" color="warning" top>
      {{ snackbarMessege }}
    </v-snackbar>
  </base-main-container>
</template>

<script>
import { getFunctions, httpsCallable } from "firebase/functions";

// import { firebaseAuth } from "../../firebase";
// import { db } from "../../firebase";

export default {
  data() {
    return {
      what: "Definition",
      topic: null,
      response:null,
      isLoading: false,
      showSnackbar: false,
      snackbarMessege: "",
      rules: {
        required: (value) => !!value || "Required.",
        max: (v) => v <= 50 || "Max 50 items",
             },
    };
  },
//   computed: {
//     premium() {
//       return this.$store.state.subscription
//         ? this.$store.state.subscription.status == "active" ||
//             this.$store.state.subscription.status == "active school" ||
//             this.$store.state.subscription.status == "trialing"
//         : false;
//     },
//   },

  methods: {
    async sendRequest() {
      this.isLoading = true;

      let  promt= [
          {
            role: "system",
            content: "You are an excellent linguist and teacher.",
          },
          {
            role: "user",
            content:
              "Can you give a dictionary definition of "+ this.topic +". If this word is not an English word, can you give definition in both English and in its own language, can you also include a sample sentence to show the usage?"
          },
        
        ]
      
      const functions = getFunctions();

      try {
        const functionRef = httpsCallable(functions, "callopenaiForResponse");
        const { data } = await functionRef({
          promt: promt,
        });

        this.response = data.result;
        this.isLoading = false;
       
       } catch (error) {
        this.isLoading = false;
        this.response = null;
       
        this.showSnackbar = true;
        this.snackbarMessege = "The system is overloaded, please try again";
      }
    },
    clearSelections() {
      this.topic = null;
    },
  },
};
</script>

<style scoped>
.custom-font {
  font-family: 'Arial', sans-serif; /* Change 'Arial' to the desired font family */
  font-size: 16px;
  font-weight: normal;
  color: black;
  letter-spacing: normal;
   padding: 12px;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
           background:#e5e4e4;
          
            border-radius: 10px;
            margin-bottom: 22.5px;
          
  /* Other font properties such as font-size, font-weight, etc. can also be applied here */
}
@media (min-width: 600px) {
  .my-card {
    width: 300px;
  }
}
@media (min-width: 960px) {
  .my-card {
    width: 500px;
  }
}
@media (min-width: 1264px) {
  .my-card {
    width: 800px;
  }
}
</style>




